import type { NextPage } from 'next'

import Head from 'next/head'
import { Skeleton } from '@mui/material'

import MESSAGES from '@/constants/messages'
import HomeHeroCarousel from '@/components/home/HomeHeroCarousel'
import CarouselData from '@/public/content/carousel.json'

const Home: NextPage = () => {
  const messages = MESSAGES.pages.home
  return (
    <>
      <Head>
        <title>{messages.title}</title>
        <meta key="description" name="description" content={messages.description} />
        <link rel="preconnect" href="https://blog.comc.com" fetchpriority="high" />
      </Head>
      {CarouselData ? (
        <>
          <HomeHeroCarousel data={CarouselData} />
        </>
      ) : (
        <Skeleton variant="rectangular" height={360} />
      )}
    </>
  )
}

export default Home
